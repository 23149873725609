@import "custom";
@import "~bootstrap/scss/bootstrap";


@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

@import url('https://fonts.googleapis.com/css2?family=Almarai&display=swap');

body {
    background-color: #FFF;
    /*    background-color: lightgray;*/
    background-image: url('../../public/img/site/bg_site.png');
    background-repeat: repeat;
    font-family: 'Almarai', sans-serif;

}

a,
.nav-link:hover,
.nav-link:focus {
    text-decoration: none !important;
}

a:hover {
    text-decoration: underline !important;
}


.tooltip-inner {
    background-color: rgb(255, 248, 207) !important;
    color: #000 ;
    max-width: 350px !important;
    opacity: 1 !important;
    border: #000 1px solid;
    filter: none !important; /* Supprime tout effet appliqué */
    box-shadow: none !important;
    
}

.bs-tooltip-bottom .arrow::before, 
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: rgb(255, 248, 207) !important;
    opacity: 1 !important; /* Supprime la transparence */
    filter: none !important; /* Supprime tout effet */
}


.carousel-indicators [data-bs-target] {
    margin-right: 0px !important;
    margin-left: 1px !important;
}

.carousel-indicators {
    margin-bottom: 6px !important;
}