@media all and (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: none;
    }

    .navbar .nav-item:hover .nav-link {}

    .navbar .nav-item:hover .dropdown-menu {
        display: block;
    }

    .navbar .nav-item .dropdown-menu {
        margin-top: 0;
    }

}

@media all and (max-width: 992px) {

    .header-bg-img {
        min-height: 20px !important;
        background-image: none !important;
        background-color: #076043;
        padding-top: 0px !important;
    }

    header .fa-solid,
    header .fa-brands {
        font-size: 20px !important;
    }
}

.card-header h2,
.card-header h1 {
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
}

.blockSocial .fa-solid,
.blockSocial .fa-brands {
    display: inline;
    font-size: 24px;
}

.blockSocial a:hover {
    text-decoration: none;
    color: white;
}

.header-bg-img {
    min-height: 80px;
    background-image: url('../../public/img/site/bg_header.png');
    background-repeat: repeat-x;
    // background-size: auto;
    background-size: auto 84px;
    background-position: center;
    // background-color: #076043;
    // padding-top: 30px;
}

header .hijridate {
    font-size: 14px;
    //font-weight: bold;
}


.logo-bg-img {
    min-height: 100px;
    background-image: url('../../public/img/site/bg-logo-banner.png');
    background-repeat: repeat-x;
    background-position: center;
    background-position: right top;
    background-size: auto 100%;
    // padding-top: 30px;
}


.logo {
    height: 130px !important;
    padding-top: 10px;
    padding-bottom: 10px;
}

header {
    padding-bottom: 10px;
}

header .fa-solid,
header .fa-brands {
    font-size: 30px;
    color: #fff;
    display: block;
}

header .fa-solid:hover,
header .fa-brands:hover {
    color: #ede183;
}

header li {
    text-align: center;
}

header li a.nav-link {
    color: #fff !important;
    font-size: 13px;
    padding: 4px 10px !important;
}

header .btn {
    color: white !important;
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
}

header li a:hover {
    color: #ede183 !important;
}

.redRslan {
    background-color: #076043 !important;
    color: #FFF !important;
}

header li .dropdown-item {
    color: #076043 !important;
}

header li a.dropdown-item:hover {
    color: #076043 !important;
}

.card-header {

    border-color: #076043 !important;
}

.card-header {
    color: #fff !important;
    background-color: #076043 !important;
}

.card a {
    color: #076043 !important;
}

.breadcrumbColor {
    background-color: #339668 !important;
    margin-top: -5px;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    margin-bottom: 5px;
}

.breadcrumb {
    background-color: #339668 !important;
    color: #f0dc79 !important;
    /* margin-top: -5px;
    padding-top: 10px !important;
    padding-bottom: 10px !important;*/
}

.breadcrumb a {
    color: #FFF !important;
}



#barre_info {
    clear: both;
    height: 30px;
    left: 0;
    line-height: 34px;
    margin: 0 auto;
    overflow: hidden;
    top: 0;
    width: 100%;
    z-index: 999;
}

#barre_info ul {
    margin: 0 auto;
    // max-width: 1200px;
}

#barre_info ul li {
    display: block;
    height: 30px;
    line-height: 30px;
    margin: 0;
    color: #f0dc79 !important;
}

#barre_info ul li:last-child {
    margin: 0;
}

#barre_info ul li a {
    font-weight: 200;
    line-height: 30px;
}

#barre_info ul li.close {
    float: right;
}

#barre_info+header {
    margin-top: 40px;
}

#barre_info+header.fix {
    margin-top: 30px;
    padding: 0;
}

#barre_info~.logo.fix,
#barre_info~h1 .logo.fix {
    margin-top: 47px;
}




.card-body {
    -webkit-box-shadow: 0 2px 4px 0 rgba(29, 79, 179, .2) !important;
    -moz-box-shadow: 0 2px 4px 0 rgba(29, 79, 179, .2) !important;
    -ms-box-shadow: 0 2px 4px 0 rgba(29, 79, 179, .2) !important;
    -o-box-shadow: 0 2px 4px 0 rgba(29, 79, 179, .2) !important;
    box-shadow: 0 2px 4px 0 rgba(29, 79, 179, .2) !important;

    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;


    -ms-box-shadow: 0 2px 3px rgba(0, 0, 0, .1) !important;
    -o-box-shadow: 0 2px 3px rgba(0, 0, 0, .1) !important;
    -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, .1) !important;
    -moz-box-shadow: 0 2px 3px rgba(0, 0, 0, .1) !important;
    box-shadow: 0 4px 8px 1px rgba(0, 0, 0, .1) !important;
    border: 1px solid !important;
    border-top-color: currentcolor !important;
    border-right-color: currentcolor !important;
    border-bottom-color: currentcolor !important;
    border-left-color: currentcolor !important;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5 !important;


    background-color: #fff;
}

.card {
    border-color: #FFF !important;
}

/* enlever la barre horizontal */
.row {
    --bs-gutter-x: 0 !important; /* Désactive le gutter horizontal */
}

/* Conteneur principal pour Like/Dislike et Partage */
.parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
}




.interaction-container {
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: #f5f5f5;
    padding: 10px 15px;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Section Like/Dislike */
.like-dislike-group {
    display: flex;
    align-items: center;
    gap: 10px;
}

.like-section, .dislike-section {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.separator-like {
    width: 1px;
    height: 25px;
    background-color: #ccc;
}

/* Icônes */
.like-icon, .dislike-icon {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
}

.like-icon:hover, .dislike-icon:hover {
    transform: scale(1.2);
}

/* Compteur de likes */
.like-count {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

/* Bouton Partager */
.share-button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.share-button:hover {
    background-color: #f0f0f0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.share-button i {
    font-size: 18px;
    color: #333;
}
